import React from 'react'

export default function Test2() {
  return (
    <div>
                <div className="testtc">
            <div className="quote">"</div>
            <div className="byname2">
            Abadur Rahman
            </div>
            <div className="byname">Employee</div>
        </div>
    </div>
  )
}
