import img1 from '../assets/labour.jpg'
import img2 from '../assets/carpenter.jpg'
import img3 from '../assets/electrician.jpg'
import img4 from '../assets/plumber.jpg'
import img5 from '../assets/Supervisor.jpg'
import img6 from '../assets/shopfitter.jpg'

const CardInfo = [{
  image:img1,
  title:'General Laborer',
  body:'Assist with various tasks on-site, including loading materials and cleaning.'
},
{
  image:img2,
  title:'Carpenter',
  body:'Build and repair structures, frameworks, and fixtures.'
},
{
  image:img3,
  title:'Electrician[Service unavailable at this moment]',
  body:'Install and maintain electrical systems.'
},
{
  image:img4,
  title:'Plumber',
  body:'Install and repair piping systems for water and gas.'
},
{
  image:img5,
  title:'Site Supervisor',
  body:'Supervise daily operations on-site, ensure safety standards.'
},
{
  image:img6,
  title:'Shopfitter',
  body:'Works to fit counter tops and shelves.'
},
]
export default CardInfo;