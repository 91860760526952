import React from 'react'

export default function Test1() {
  return (
    <div>
        <div className="testtc">
            <div className="quote">"</div>
            <div className="byname2">Arifa Begum</div>
            <div className="byname"> Employee</div>
        </div>
    </div>
  )
}
